import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'qrcode'

const GetAll = (data, pagination) => SocialProjectManager.get(resourceName, { 
  params: merge(data, pagination) 
})
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)
const UpdateQRCode = data => SocialProjectManager.put(`${resourceName}/update`, data)
const SaveQRCodeSingle = data => SocialProjectManager.post(`${resourceName}/single`, data)
const SaveQRCodeEmployee = data => SocialProjectManager.post(`${resourceName}/employee`, data)
const SaveQRCodeStudent = data => SocialProjectManager.post(`${resourceName}/student`, data)
const SaveQRCodeTeacher = data => SocialProjectManager.post(`${resourceName}/teacher`, data)

const QRCodeRepository = {
  GetAll,
  GetById,
  SaveQRCodeSingle,
  SaveQRCodeEmployee,
  SaveQRCodeStudent,
  SaveQRCodeTeacher,
  Delete,
  UpdateQRCode
}

export default QRCodeRepository