import QRCodeModel from '@/shared/models/qrcodeModel'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import QRCodeRepository from '@/shared/http/repositories/socialProject/qrcode'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'

export default {
  name: 'QRCode',

	props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    isEmployee: {
      type: Boolean,
      default: false
    },
    isStudent: {
      type: Object,
      default: null
    },
    isTeacher: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    qrcodeModel: new QRCodeModel(),
    optionSendToEmail: false,
		mealsQuantity: 0,
    emails: []
  }),

  computed: {
	},

  created() {
  },

  watch: {
    isStudent: {
      handler: function (value) {
        if (value) {
          this.qrcodeModel.name = value.name
          this.qrcodeModel.rg = value.documentRG
          this.qrcodeModel.studentId = value.id
          this.emails = value.email
        }
      },
      immediate: true
    },

    isTeacher: {
      handler: function (value) {
        if (value) {
          this.qrcodeModel.name = value.name
          this.qrcodeModel.rg = value.documentRG
          this.qrcodeModel.teacherId = value.id
        }
      },
      immediate: true
    },

    optionSendToEmail: {
      handler: function (value) {
        if (!value) {
          this.qrcodeModel.email = null
        }

        if (this.isTeacher && value) {
          this.getEmailTeacher(this.isTeacher.id)
        }
      },
      immediate: true
    }
  },

  methods: {
    onCheckSaveQRCode() {
      if (this.optionSendToEmail && !this.qrcodeModel.email) {
        return toast.info('Selecione um e-mail', 'ENVIO POR E-MAIL')
      }

      if(this.isSingle != null && this.isSingle)
        this.onSaveQRCodeSingle()
        
      if(this.isEmployee != null && this.isEmployee)
        this.onSaveQRCodeEmployee()

      if(this.isStudent != null && this.isStudent)
        this.onSaveQRCodeStudent()

      if(this.isTeacher != null && this.isTeacher)
        this.onSaveQRCodeTeacher()
    },
		onSaveQRCodeSingle() {
      loading.push()
      
      if(!this.qrcodeModel.name || this.qrcodeModel.name.trim() == '' || !this.qrcodeModel.formatQRCode) {
        toast.warning('Preencha os campos antes de criar o QRCode', 'CRIAÇÃO DE QRCODE')
        loading.pop()
        return
      }
			this.qrcodeModel.mealsQuantity = parseInt(this.mealsQuantity)
      this.qrcodeModel.isEmployee = false
      this.qrcodeModel.isSingle = true
      QRCodeRepository.SaveQRCodeSingle(this.qrcodeModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (!this.optionSendToEmail) {
            const file = res.data.data.file
            const base64 = `data:${file.contentType};base64,${file.base64}`
            download(base64, file.alias, file.contentType)
          } else {
            toast.success('E-mail enviado para: ' + this.qrcodeModel.email, 'ENVIO DE E-MAIL')
          }
          
          this.$emit('closeModal')
          this.clearInputs()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO AO GERAR QRCODE')
        })
    },

    onSaveQRCodeEmployee() {
      loading.push()
      
      if( !this.qrcodeModel.name  || this.qrcodeModel.name.trim() == '' || !this.qrcodeModel.rg || this.qrcodeModel.rg.trim() == '' || !this.qrcodeModel.formatQRCode) {
        toast.warning('Preencha os campos antes de criar o QRCode', 'CRIAÇÃO DE QRCODE')
        loading.pop()
        return
      }
			this.qrcodeModel.mealsQuantity = parseInt(this.mealsQuantity)
      this.qrcodeModel.isEmployee = true
      this.qrcodeModel.isSingle = false
      QRCodeRepository.SaveQRCodeEmployee(this.qrcodeModel)
        .then(res => {
          if (!IsSuccessCode(res)) {
            const { code, message } = res.data
            if (code == -1003) {
              toast.info(message, 'USUÁRIO JÁ ATIVO')
              loading.pop()
              return Promise.resolve()
            }
            return Promise.reject()
          }
          if (!this.optionSendToEmail) {
            const file = res.data.data.file
            const base64 = `data:${file.contentType};base64,${file.base64}`
            download(base64, file.alias, file.contentType)
          } else {
            toast.success('E-mail enviado para: ' + this.qrcodeModel.email, 'ENVIO DE E-MAIL')
          }

          this.clearInputs()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO AO GERAR QRCODE')
        })
    },

    onSaveQRCodeStudent() {
      loading.push()
      
      if (!this.qrcodeModel.name  || this.qrcodeModel.name.trim() == '' || !this.qrcodeModel.rg || this.qrcodeModel.rg.trim() == '' || !this.qrcodeModel.formatQRCode) {
        toast.warning('Preencha os campos antes de criar o QRCode', 'CRIAÇÃO DE QRCODE')
        loading.pop()
        return
      }

      if (this.qrcodeModel.formatQRCode == 'littleWallet') {
        this.qrcodeModel.name = this.cutName(this.qrcodeModel.name)
      }

			this.qrcodeModel.mealsQuantity = parseInt(this.mealsQuantity)
      this.qrcodeModel.isEmployee = false
      this.qrcodeModel.isSingle = false
      QRCodeRepository.SaveQRCodeStudent(this.qrcodeModel)
        .then(res => {
          if (!IsSuccessCode(res)) {
            const { code, message } = res.data
            if (code == -1003) {
              toast.info(message, 'USUÁRIO JÁ ATIVO')
              loading.pop()
              return Promise.resolve()
            }
            return Promise.reject()
          }

          if (!this.optionSendToEmail) {
            const file = res.data.data.file
            const base64 = `data:${file.contentType};base64,${file.base64}`
            download(base64, file.alias, file.contentType)
          } else {
            toast.success('E-mail enviado para: ' + this.qrcodeModel.email, 'ENVIO DE E-MAIL')
          }

          this.$emit('closeModal')
          this.clearInputs()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO AO GERAR QRCODE')
        })
    },

    onSaveQRCodeTeacher() {
      loading.push()
      
      if (!this.qrcodeModel.name  || this.qrcodeModel.name.trim() == '' || !this.qrcodeModel.rg || this.qrcodeModel.rg.trim() == '' || !this.qrcodeModel.formatQRCode) {
        toast.warning('Preencha os campos antes de criar o QRCode', 'CRIAÇÃO DE QRCODE')
        loading.pop()
        return
      }

      if (this.qrcodeModel.formatQRCode == 'littleWallet') {
        this.qrcodeModel.name = this.cutName(this.qrcodeModel.name)
      }

			this.qrcodeModel.mealsQuantity = parseInt(this.mealsQuantity)
      this.qrcodeModel.isEmployee = false
      this.qrcodeModel.isSingle = false
      QRCodeRepository.SaveQRCodeTeacher(this.qrcodeModel)
        .then(res => {
          if (!IsSuccessCode(res)) {
            const { code, message } = res.data
            if (code == -1003) {
              toast.info(message, 'USUÁRIO JÁ ATIVO')
              loading.pop()
              return Promise.resolve()
            }
            return Promise.reject()
          }

          if (!this.optionSendToEmail) {
            const file = res.data.data.file
            const base64 = `data:${file.contentType};base64,${file.base64}`
            download(base64, file.alias, file.contentType)
          } else {
            toast.success('E-mail enviado para: ' + this.qrcodeModel.email, 'ENVIO DE E-MAIL')
          }

          this.$emit('closeModal')
          this.clearInputs()

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO AO GERAR QRCODE')
        })
    },

    getEmailTeacher(id) {
      this.emails = []

      loading.push()
      TeacherRepository.GetEmail(id)
        .then(res => {
          if (!IsSuccessCode(res)) {
            return Promise.reject()
          }

          const email = res.data.data.email

          if (email) this.emails.push(email)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar e-mail', 'ERRO')
        })
    },

    cutName(name) {
      if (name == 'N/D') return 'N/D'

      const fullName = name
      const firstName = fullName.split(" ")[0]
      const fullNameLength = fullName.split(" ").length
      const lastName = fullName.split(" ")[fullNameLength-1] 
      const shortName = firstName + ' ' + lastName

      return shortName
    },

    clearInputs() {
      this.qrcodeModel = new QRCodeModel()
      this.mealsQuantity = 0
      this.emails = []
      this.optionSendToEmail = false
    },

    validateEmail(email) {
      let regex = /\S+@\S+\.\S+/
      let result = regex.test(email)

      if (!result) return false

      return true
    },

    addInputEmail(email) {
      if (!email) return
      this.qrcodeModel.email = email
    }
  }
}
