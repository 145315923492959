import GuidUtils from '@/shared/utils/guid'

export default class QRCodeModel {
  constructor({
    id,
    registrationDate,
    studentId,
    teacherId,
    isSingle,
    isEmployee,
    name,
    mealsQuantity,
    rg,
    file,
    formatQRCode,
    email
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.teacherId = teacherId,
    this.registrationDate = registrationDate,
    this.studentId = studentId
    this.isSingle = isSingle
    this.isEmployee = isEmployee
    this.name = name
    this.mealsQuantity = mealsQuantity
    this.rg = rg
    this.file = file,
    this.formatQRCode = formatQRCode,
    this.email = email
  }
}